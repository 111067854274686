@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.ListItem {
  background: #fff;
  height: 90px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #E9E3DF; }
  .ListItem .content {
    height: 90px;
    width: 100%;
    padding: 15px 17px; }
  .ListItem .selectionChit {
    background-color: #F67902;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0px;
    transition: 0.2s all ease; }

.ListItem:hover .selectionChit {
  width: 6px; }

.ListItem.isSelected .selectionChit,
.ListItem.isSelected:hover .selectionChit {
  width: 3px; }

.OnOffItem {
  position: relative;
  padding: 8px 90px 8px 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05); }
  .OnOffItem .content {
    margin: 0;
    opacity: 0.7; }
  .OnOffItem .switchWrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; }

.OnOffItem.on .content,
.OnOffItem.disableDim .content {
  opacity: 1; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.Switch {
  width: 50px;
  height: 22px;
  background: #C3BCB9;
  border-radius: 11px;
  position: relative;
  font-size: 10px;
  cursor: pointer;
  transition: .5s ease; }

.Switch .indicator {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  position: absolute;
  left: 1px;
  top: 1px;
  z-index: 10;
  transition: .5s ease; }

.Switch .onLabel {
  position: absolute;
  left: 9px;
  top: 0;
  height: 22px;
  text-align: left;
  line-height: 22px;
  padding-top: 1px;
  z-index: 1;
  transition: .3s ease;
  opacity: 0; }

.Switch .offLabel {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1px;
  padding-right: 6px;
  text-align: right;
  line-height: 22px;
  color: #413B38;
  z-index: 1;
  transition: .3s ease;
  opacity: 1; }

.Switch:hover {
  background: #A8A4A2; }

.Switch.on,
.Switch.on:hover {
  background: #FFA900; }

.Switch.on .indicator {
  left: 29px; }

.Switch.on .onLabel {
  opacity: 1; }

.Switch.on .offLabel {
  opacity: 0; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.Pill {
  background-color: #fff;
  padding: 0 0 0 19px;
  line-height: 38px;
  display: inline-block;
  border-radius: 19px;
  margin: 0 10px 10px 0; }
  .Pill span {
    line-height: 38px; }
  .Pill span.deleteButton {
    float: right;
    display: block;
    width: 40px;
    text-align: center;
    cursor: pointer; }
  .Pill span.deleteButton:hover {
    color: #F67902; }
  .Pill span.deleteButton:active {
    color: #FFA900; }

.Pill.isDark {
  background-color: #F0ECE9; }

.Pill.hideDelete {
  padding: 0 19px; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.NavTextButton {
  cursor: pointer;
  position: relative;
  float: left;
  color: #5E5450; }
  .NavTextButton .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.NavTextButton:hover .text {
  color: #F67902; }

.NavTextButton:active .text {
  color: #FFA900; }

.NavTextButton.isLoading {
  cursor: default; }
  .NavTextButton.isLoading .text {
    opacity: 0; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.SortAndSearch {
  background-color: #fff; }
  .SortAndSearch .header {
    position: relative;
    height: 66px;
    border-bottom: 1px solid #DAD3D0; }
    .SortAndSearch .header .sortOptions {
      transition: 0.3s all ease;
      line-height: 66px;
      z-index: 10;
      position: absolute;
      top: 0;
      right: 100px;
      left: 15px;
      bottom: 0; }
      .SortAndSearch .header .sortOptions .sortOptionsButton {
        cursor: pointer; }
      .SortAndSearch .header .sortOptions .sortOptionsButton:hover span {
        color: #F67902; }
      .SortAndSearch .header .sortOptions .sortOptionsDropdown {
        top: 55px;
        left: -0;
        position: absolute;
        background: #fff;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        width: 177px;
        overflow: hidden; }
    .SortAndSearch .header .searchWrapper {
      padding: 0 15px;
      position: absolute;
      top: 0;
      right: 15px;
      left: 15px;
      bottom: 0;
      z-index: 1; }
    .SortAndSearch .header .searchArea {
      position: absolute;
      top: 15px;
      right: 0;
      left: auto;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      border: 1px solid #fff;
      transition: 0.4s all ease;
      overflow: hidden; }
      .SortAndSearch .header .searchArea svg {
        color: #A8A4A2; }
      .SortAndSearch .header .searchArea input {
        opacity: 0;
        border: none;
        outline: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        padding: 0 30px 0 10px;
        z-index: 50; }
    .SortAndSearch .header .searchArea:hover {
      border-color: #E9E3DF; }
    .SortAndSearch .header .searchButton {
      cursor: pointer;
      z-index: 100;
      border-radius: 18px;
      position: absolute;
      top: 15px;
      right: 0;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .SortAndSearch .header .searchButton:hover {
      color: #F67902; }
  .SortAndSearch .header.isShowingSearch .sortOptions {
    opacity: 0; }
  .SortAndSearch .header.isShowingSearch .searchWrapper {
    z-index: 100; }
  .SortAndSearch .header.isShowingSearch .searchArea {
    width: 100%;
    border-color: #E9E3DF;
    z-index: 100; }
    .SortAndSearch .header.isShowingSearch .searchArea input {
      opacity: 1; }
    .SortAndSearch .header.isShowingSearch .searchArea svg {
      color: #F67902; }
  .SortAndSearch .header.isShowingSearch .searchArea:hover {
    border-color: #E9E3DF; }

.SortAndSearchSortOption {
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 18px;
  cursor: pointer; }

.SortAndSearchSortOption:hover {
  background: #FFF5E2;
  color: #F67902; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.button:hover {
  background: #F67902; }

.button:active {
  background: #FFA900; }

.button.is-dashed:focus,
.button.is-dashed {
  color: #413B38;
  border: 1px dashed #C3BCB9;
  background-color: transparent; }

.button.is-dashed:active,
.button.is-dashed:hover {
  color: #413B38;
  border: 1px dashed #413B38;
  background-color: transparent; }

.button.is-dashed:active {
  color: #F67902; }

.button.is-outlined {
  color: #413B38;
  border: 1px solid #C3BCB9;
  background-color: transparent; }

.button.is-outlined:hover {
  color: #FFFFFF;
  background: #413B38;
  border: 1px solid #413B38; }

.button.is-tan, .button.is-tan:active, .button.is-tan:focus {
  margin-right: 10px;
  background-color: #F5F1EF;
  color: #413B38; }

.button.is-tan.is-selected,
.button.is-tan:hover {
  background-color: #413B38;
  color: #FFF; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.TabHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 39px;
  border-bottom: 1px solid #DAD3D0; }
  .TabHeader .tab {
    font-size: 13px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #413B38;
    cursor: pointer;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    position: relative;
    z-index: 100; }
    .TabHeader .tab:hover {
      border-bottom: 2px solid orange; }
    .TabHeader .tab.selected {
      color: #F67902;
      border-bottom: 2px solid orange; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.PillSwitch {
  background-color: #F5F1EF;
  color: #A8A4A2;
  padding: 0 19px 0 19px;
  line-height: 38px;
  display: inline-block;
  border-radius: 19px;
  margin: 0 10px 10px 0;
  cursor: pointer; }

.PillSwitch:hover {
  background-color: #FFA900;
  color: #fff; }

.PillSwitch:active {
  background-color: #FFF5E2;
  color: #FFA900; }

.PillSwitch.isOn {
  background-color: #FFF5E2;
  color: #F67902; }

.PillSwitch.isOn:active {
  background-color: #FFF5E2;
  color: #FFA900; }

.PillSwitch.isDark {
  background-color: #F0ECE9; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.SaveInput {
  margin-bottom: 10px;
  color: #A8A4A2; }
  .SaveInput .leftTitle {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    color: #A8A4A2;
    font-size: 14px;
    float: left;
    clear: both; }
  .SaveInput .rightTitle {
    margin-bottom: 0;
    color: #A8A4A2;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    float: right; }
  .SaveInput .inputBlock {
    width: 100%;
    float: left;
    padding: 10px;
    background: #F0ECE9;
    border-radius: 6px;
    margin-bottom: 10px; }
  .SaveInput .inputWrapper {
    position: relative;
    border: 1px solid #F0ECE9;
    width: 100%;
    height: 32px;
    padding: 0 60px 0 10px;
    border-radius: 4px;
    background: #FFFFFF; }
  .SaveInput .inputWrapper:hover {
    border: 1px solid #A8A4A2; }
  .SaveInput input {
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0;
    margin: 0;
    outline: none;
    border: none; }
  .SaveInput .saveButton {
    display: none;
    width: 50px;
    border-radius: 3px;
    background: #5E5450;
    color: #FFFFFF;
    position: absolute;
    right: 5px;
    top: 3px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    padding: 0; }
    .SaveInput .saveButton .title {
      font-weight: bold;
      color: #FFFFFF;
      font-size: 12px; }
  .SaveInput .saveButton:hover {
    background: #F67902; }
  .SaveInput .cancelButton {
    font-weight: bold;
    display: none;
    width: 50px;
    border-radius: 3px;
    background: #FFFFFF;
    position: absolute;
    right: 60px;
    top: 3px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    color: #A8A4A2;
    font-size: 12px; }
  .SaveInput .cancelButton:hover {
    color: #F67902; }

.SaveInput.editing .saveButton,
.SaveInput:hover .saveButton {
  display: block; }

.SaveInput.editing .cancelButton {
  display: block; }

.SaveInput.editing .inputWrapper {
  border: 1px solid #FFA900; }

.SaveInput.editing .saveButton {
  background: #FFA900; }

.SaveInput.editing .saveButton:hover {
  background: #F67902; }

.SaveInput .spinner {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center; }

.SaveInput.loading .spinner {
  display: flex; }

.SaveInput.loading .saveButton .title {
  color: rgba(0, 0, 0, 0); }

.SaveInput.white .inputBlock {
  padding: 0;
  background: #FFFFFF;
  border-radius: 0; }

.SaveInput.greyOutline .inputBlock {
  background: none;
  padding: 0; }

.SaveInput.greyOutline .inputWrapper {
  background: #FFFFFF;
  border-color: #A8A4A2; }

@keyframes gg-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes gg-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.Checkbox {
  position: relative;
  cursor: pointer; }
  .Checkbox input[type="checkbox"] {
    opacity: 0; }
  .Checkbox .checkboxChildren {
    margin-left: 10px;
    line-height: 16px; }

.Checkbox.isDisabled {
  opacity: 0.5; }

.Checkbox::before {
  top: 3px;
  position: absolute;
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  border: 1px solid #5E5450;
  transition: 0.2s all; }

.Checkbox.isChecked::before {
  border: 1px solid #FFA900;
  background-color: #FFA900; }

.Checkbox:active::before {
  border: 1px solid #F67902;
  background-color: #F67902; }

.Checkbox:hover::after,
.Checkbox.isChecked::after {
  left: 2px;
  top: 7px;
  position: absolute;
  content: "";
  display: inline-block;
  height: 7px;
  width: 12px;
  border-left: 3px solid #5E5450;
  border-bottom: 3px solid #5E5450;
  transform: rotate(-45deg); }

.Checkbox:active::after,
.Checkbox.isChecked::after {
  border-color: #fff; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.ButtonDropdown {
  position: relative;
  display: inline-block;
  z-index: 1; }
  .ButtonDropdown .dropdown {
    position: absolute;
    top: 45px;
    /* position the top  edge of the element at the middle of the parent */
    left: 50%;
    transform: translate(-50%, 0);
    width: 263px;
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    z-index: 9999;
    border-radius: 12px;
    display: flex;
    flex-direction: column; }
    .ButtonDropdown .dropdown .searchArea {
      width: 100%;
      padding: 10px; }
    .ButtonDropdown .dropdown .dropdownContent {
      width: 100%;
      clear: both;
      max-height: 250px;
      overflow-x: hidden;
      overflow-y: scroll; }
      .ButtonDropdown .dropdown .dropdownContent .noResults {
        width: 100%;
        font-weight: 600;
        color: #A8A4A2;
        font-size: 15px;
        text-align: center;
        padding: 5px 10px; }

.ButtonDropdown.isShowingDropdown {
  z-index: 9999; }
  .ButtonDropdown.isShowingDropdown .Button {
    border-color: #F67902;
    background-color: #FFF5E2; }

.ButtonDropdownItem {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  color: #5E5450;
  cursor: pointer; }

.ButtonDropdownItem:hover,
.ButtonDropdownItem.isSelected {
  background-color: #FFF5E2;
  color: #F67902; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.SelectionListItem {
  position: relative;
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
  color: #413B38;
  cursor: pointer;
  z-index: 100; }

.SelectionListItem:hover {
  background-color: #E9E3DF; }

.SelectionListItem:active {
  color: #F67902;
  font-weight: 550; }

.SelectionListItem.isSelected {
  color: #F67902;
  background-color: transparent;
  font-weight: 550;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fff; }

.SelectionListItem.isSelected:hover {
  background-color: #fff; }

.SelectionList {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll; }
  .SelectionList .itemsWrapper {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    z-index: 100; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.DragableListItem {
  background: #F0ECE9;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 0;
  border-radius: 4px; }
  .DragableListItem.dragging {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); }
    .DragableListItem.dragging .DragableListItemDragHandle {
      color: #A8A4A2; }
  .DragableListItem .dragableContent {
    flex: 1; }
  .DragableListItem .removeButton {
    cursor: pointer;
    flex-grow: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #DAD3D0; }
    .DragableListItem .removeButton:active {
      color: #E92200; }
    .DragableListItem .removeButton:hover {
      color: #E92200; }
  .DragableListItem .DragableListItemDragHandle {
    cursor: ns-resize;
    flex-grow: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #DAD3D0; }
    .DragableListItem .DragableListItemDragHandle:active {
      color: #A8A4A2; }
    .DragableListItem .DragableListItemDragHandle:hover {
      color: #A8A4A2; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.ButtonWithOptions {
  position: relative;
  float: left;
  clear: both; }
  .ButtonWithOptions .button {
    z-index: 10; }
  .ButtonWithOptions .button:first-child {
    margin-right: 2px; }
  .ButtonWithOptions .button.chevron {
    border: none;
    padding-left: 0.7em;
    padding-right: 0.7em;
    border-radius: 0 4px 4px 0 !important; }
    .ButtonWithOptions .button.chevron svg {
      transition: 0.3s all ease; }
  .ButtonWithOptions .button.chevron:hover {
    border: none; }
  .ButtonWithOptions .treyWrapper {
    padding-top: 5px;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.3s all ease;
    opacity: 0; }
    .ButtonWithOptions .treyWrapper .button {
      width: 100%;
      margin-bottom: 5px; }

.ButtonWithOptions.isShowingMenu {
  z-index: 1000; }
  .ButtonWithOptions.isShowingMenu .chevron {
    background: #F67902; }
    .ButtonWithOptions.isShowingMenu .chevron svg {
      transform: rotate(180deg); }
  .ButtonWithOptions.isShowingMenu .treyWrapper {
    opacity: 1;
    top: 100%; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.MultiSelectListItem {
  transition: 0.3s background-color ease;
  position: relative;
  height: 40px;
  padding: 0 20px 0 10px;
  border-radius: 4px;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  color: #413B38;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  background: #F5F1EF;
  border: 1px solid #F5F1EF; }
  .MultiSelectListItem .Checkbox {
    margin-top: -3px;
    margin-right: 8px; }

.MultiSelectListItem:hover {
  border: 1px solid #F67902; }

.MultiSelectListItem:active {
  border: 1px solid #FFA900; }

.MultiSelectListItem.isFocused {
  border: 1px solid #F67902; }

.MultiSelectListItem.isSelected {
  background-color: #FFF5E2; }

.MultiSelectList .itemsWrapper {
  left: 20px;
  right: 20px;
  top: 0;
  z-index: 100; }

.LoadingItem {
  background: #fff;
  height: 90px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

input[type=radio] {
  margin-right: 5px; }

.clearfix::after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.noselect {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.ListItemContentTextAndFooter .text {
  color: #413B38;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.ListItemContentTextAndFooter .itemFooter span {
  font-size: 15px;
  color: #A8A4A2; }

.ListItemContentTextAndFooter .itemFooter span:last-child {
  float: right; }
